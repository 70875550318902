import React, { useState } from 'react';
import { useRecordContext, useRefresh, useNotify, RaRecord } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { omit } from 'ramda';
import { addDays, differenceInDays, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Button } from '@mui/material';
import create from 'utils/api/create';

const omitAttributes = [
  '__typename',
  'id',
  'signed_addendum',
  'per_diem',
  'administrator',
  'administrator_type',
  'referrer_professional',
  'recruiter',
  'vms_order_url',
  'self_schedule',
  'positions',
  'surgery_types',
  'specialties',
  'booked_at',
  'cancellation_reason',
  'canceled_by_professional',
  'estimated_unpaid_break_time_minutes',
  'estimated_total_hours',
  'enforce_guaranteed_minimum_hours',
  'created_at',
  'updated_at',
  'clinical_contact',
  'clinical_contact_type',
  'taxable_amount_cents',
  'nontaxable_amount_cents',
  'payout_base_hourly_rate_currency',
  'charge_base_hourly_rate_currency',
  'payout_on_call_hourly_rate_currency',
  'charge_on_call_hourly_rate_currency',
  'payout_taxable_hourly_rate_currency',
  'max_mie_per_diem_cents',
  'max_lodging_per_diem_cents',
  'min_taxable_rate_cents',
  'social_security_burden_multiplier',
  'medicare_burden_multiplier',
  'federal_unemployment_max_burden_cents',
  'state_unemployment_max_burden_cents',
  'other_burden_multiplier',
  'available_professional_count',
  'assignment_bonus_tier2_travel_amount_cents',
  'assignment_bonus_tier2_travel_amount_tcv_cents',
  'assignment_bonus_tier1_travel_amount_cents',
  'assignment_bonus_tier1_travel_amount_tcv_cents',
  'w2_contract',
  'offer',
  'locations',
  'assignment_locations',
  'primary_location',
  'primary_location_type',
  'invoices',
  'company_id',
  'company',
  'company_type',
  'recruiter_type',
];

const recordToParams = (record: RaRecord) => {
  const fields = omit(omitAttributes, record);
  const now = new Date();
  const diff = differenceInDays(now, new Date(record.starts_date));
  let startsDate = record.starts_date;
  let endsDate = record.ends_date;
  let startsTime = record.starts_time;
  let endsTime = record.ends_time;

  if (diff > 0) {
    const oldStartsTime = utcToZonedTime(parseISO(record.starts_time), 'Etc/UTC');
    const oldEndsTime = utcToZonedTime(parseISO(record.ends_time), 'Etc/UTC');

    startsDate = format(addDays(new Date(record.starts_date), diff), 'yyyy-MM-dd');
    endsDate = format(addDays(new Date(record.ends_date), diff), 'yyyy-MM-dd');
    startsTime = format(addDays(oldStartsTime, diff), "yyyy-MM-dd'T'HH:mm:ss'Z'", {
      timeZone: 'Etc/UTC',
    });
    endsTime = format(addDays(oldEndsTime, diff), "yyyy-MM-dd'T'HH:mm:ss'Z'", {
      timeZone: 'Etc/UTC',
    });
  }

  return {
    ...fields,
    is_w2: true,
    local_only: false,
    federal_unemployment_percent_burden_multiplier: 0.006,
    state_unemployment_percent_burden_multiplier: 0.041,
    adp_fee_burden_cents: 1125,
    medical_burden_cents: 6923,
    compliance_burden_cents: 23000,
    one_time_bonus_burden_cents: 0,
    vms_fee_percent: null,
    starts_date: startsDate,
    ends_date: endsDate,
    starts_time: startsTime,
    ends_time: endsTime,
  };
};

const DuplicateW2TravelButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleDuplicate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        assignment: recordToParams(record),
      };
      const response = await create('/v3/admin/assignments', payload);
      refresh();
      navigate(`/Assignment/${response.data.id}/show`);
      notify('Assignment has been successfully duplicated', { type: 'success' });
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      disabled={isLoading}
      onClick={handleDuplicate}
    >
      {isLoading ? 'Please, wait...' : 'Duplicate W2 Travel'}
    </Button>
  );
};

export default DuplicateW2TravelButton;
