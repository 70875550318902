import React from 'react';
import { ENUMS } from 'components/Enum/enums';
import { IPostingField } from 'components/PostingField';
import { BooleanField, NumberField, RaRecord, TextField } from 'react-admin';
import _ from 'lodash';

const Status = ENUMS.Assignment.status;

export const isAvailableForCancel = (record) =>
  [Status.booked, Status.in_progress, Status.w2_onboarding].includes(record?.status);

export const isCanceled = (record) => Status.canceled === record?.status;

export const hoursPerWeek = _.flowRight<Pick<any, any>, any, any>(
  (val) => _.reduce<number, number>(val, _.multiply, 1),
  _.values,
  // @ts-ignore
  (obj) => _.pick(obj, ['number_of_shifts_per_week', 'hours_per_shift']),
);

export const getPostingFieldComponent = (postingField: IPostingField, record: RaRecord) => {
  const { field_type, slug, label } = postingField;
  const source = `posting_field_contents.${slug}.content`;
  switch (field_type) {
    case 'string':
      return <TextField label={label} source={source} />;
    case 'number':
      return <NumberField label={label} source={source} />;
    case 'boolean': {
      const slugValue = record.posting_field_contents?.[slug]?.content;
      const updatedBooleanValueRecord = slugValue
        ? {
            posting_field_contents: {
              [slug]: {
                content: slugValue === 't',
              },
            },
          }
        : {};
      return <BooleanField label={label} source={source} record={updatedBooleanValueRecord} />;
    }
    case 'single_select':
      return <TextField label={label} source={source} />;
    case 'multi_select':
      return <TextField label={label} source={source} />;
    default:
      return <div>Unknown field_type</div>;
  }
};
