import React, { useState } from 'react';
import { EditButton, useRefresh, useNotify, useRecordContext, TopToolbar } from 'react-admin';
import { Button, CircularProgress, ButtonGroup, Menu, MenuItem } from '@mui/material';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { Link } from 'react-router-dom';
import DuplicateW2TravelButton from 'resources/assignments/DuplicateW2TravelButton';
import ApproveDraft from './ApproveDraft';
import CancelButton from './CancelButton';
import { W2ContractField } from './W2ContractField';
import config from 'config';
import { helloSignClient } from 'utils/helloSign';
import { ENUMS } from 'components/Enum/enums';
import { useMenu } from 'hooks/useMenu';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useClipboard from 'hooks/useClipboard';
import useNotifyWrap from 'hooks/useNotifyWrap';
import DateInput from 'components/DateInput';
import QuickCreateButton from 'components/QuickCreateButton';
import { addDays, parse, format } from 'date-fns/fp';
import useAssignmentDuplicate from './useAssignmentDuplicate';
import getAxios from 'getAxios';
import _ from 'lodash';

const getExtensionStartsDate = _.flowRight(
  format('yyyy-MM-dd'),
  addDays(1),
  parse(new Date(), 'yyyy-MM-dd'),
);

const axios = getAxios();

const AssignmentShowActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [deleting, setDeleting] = useState(false);
  const duplicateAssignment = useAssignmentDuplicate();
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const onSubmitPreview = async () => {
    return axios
      .post(
        `/v3/admin/assignments/${record.id}/send_addendum`,
        { type: 'addendum' },
        {
          withCredentials: false,
        },
      )
      .then((x) => {
        notify('extracting offer info... loading preview...');
        refresh();
        helloSignClient.open(x['data']['claim_url']);
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  };

  const onDeleteContract = async () => {
    setDeleting(true);
    return axios
      .post(`/v3/admin/assignments/${record.id}/delete_addendum`)
      .then(() => {
        notify('Delete Request Successful');
        refresh();
      })
      .catch((error) => notify(error.message, { type: 'warning' }))
      .finally(() => setDeleting(false));
  };

  const onSubmitReminder = async () => {
    return axios
      .post(`/v3/admin/assignments/${record.id}/addendum_reminder`)
      .then(() => {
        notify('Success');
        refresh();
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  };

  return (
    <TopToolbar>
      <CopyLink />
      <W2ContractField />
      <CancelButton />
      <ApproveDraft />
      <Button
        size="small"
        variant="outlined"
        color="primary"
        disabled={duplicateAssignment.loading}
        onClick={duplicateAssignment.duplicate}
      >
        {duplicateAssignment.loading ? 'Loading...' : 'Duplicate'}
      </Button>
      {!record?.is_w2 && record?.local_only && <DuplicateW2TravelButton />}
      <EditButton />
      {record?.self_schedule && record?.staff && (
        <Button
          component={Link}
          to={`/Timecard/create?assignment_id=${record.id}&professional_id=${record.staff.id}&user_id=${record.user.id}`}
        >
          Add timecard
        </Button>
      )}
      {record?.offer === 'signed' && (
        <QuickCreateButton
          buttonText="+ Create Extension"
          title="Create Assignment Extension"
          subheader="Create a new Assignment as an extension of this one"
          resource="Assignment"
          initialValues={{ starts_date: getExtensionStartsDate(record.ends_date) }}
          url={`/v3/admin/assignments/${record.id}/extension`}
        >
          <DateInput autoFocus name="starts_date" label="Start Date" required />
          <DateInput name="ends_date" label="End Date" required />
        </QuickCreateButton>
      )}
      {record?.offer === 'signed' && (
        <ButtonGroup size="small">
          <Button
            aria-label="send offer"
            variant="outlined"
            onClick={record?.signed_addendum === false ? onSubmitReminder : onSubmitPreview}
            color="primary"
            size="small"
          >
            Send Addendum {record?.signed_addendum === false ? 'Reminder' : 'Contract'}
          </Button>
          <Button disabled={record?.signed_addendum !== false} onClick={onDeleteContract}>
            {deleting ? <CircularProgress size={20} /> : <DeleteOutline />}
          </Button>
        </ButtonGroup>
      )}
    </TopToolbar>
  );
};

export default AssignmentShowActions;

const availableOrMatched = (a) =>
  [ENUMS.Assignment.status.available, ENUMS.Assignment.status.matched].includes(a.status);

const CopyLink = () => {
  const record = useRecordContext();
  const { triggerProps, menuProps } = useMenu();
  const copy = useNotifyWrap(useClipboard(), {
    onSuccess: 'Copied',
  });

  if (!availableOrMatched(record)) {
    return null;
  }
  return (
    <>
      <Button
        size="small"
        {...triggerProps}
        variant="outlined"
        color="primary"
        endIcon={<ExpandMore />}
      >
        Copy link
      </Button>
      <Menu {...menuProps}>
        <MenuItem
          onClick={() => copy(`${config.client_url.professional}/assignments/${record.id}`)}
        >
          Assignment
        </MenuItem>
        <MenuItem onClick={() => copy(`${config.rails_url}/assignments_boards/${record.id}`)}>
          Assignment board
        </MenuItem>
        <MenuItem
          onClick={() =>
            copy(`${config.client_url.professional}/available/assignments/${record.id}/application`)
          }
        >
          Assignment application
        </MenuItem>
      </Menu>
    </>
  );
};
